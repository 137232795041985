.checkBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    align-items: flex-start;
}
label{
    font-size: 14px;
    font-weight: 400;
    color: #101828;
    line-height: 24px;
}
.checkboxInput{
    border:#0E497A;

}
input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;
    font: inherit;
    color: #0E497A;
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    border: 0.15em solid #0E497A;
    border-radius: 3px;
    transform: translateY(3.5px);
    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: #0E497A;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  