.chatbotMain {
  background-color: white;
  position: fixed;
  bottom: 1vh;
  right: 0px;
  border-radius: 20px !important;
  max-width: 420px;
  min-width: 255px;
  height: 90vh;
  width: 99vw;
  display: flex;
  flex-direction: column;
  border: 2px solid #F5C142;
  
}

.chatbotMain .dividerCustom {
  border: 1px solid rgba(0, 0, 0, 0.1);
  // margin: 12px 20px;
}
