.Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.FormContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap:12px;
    margin-bottom: 15px;
}
.SubmitBtn{
    background-color: #0E497A;
    border-radius: 100px;
    width: 75%;
    color:#fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 39px;
    border:none;
    outline:none;
    margin: auto;
}
.SubmitBtn:disabled,
.SubmitBtn[disabled]{
    background: #C1CDD7;
    user-select: none;
}